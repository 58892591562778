import Layout from "./components/Layout/Layout";
import About from "./pages/About/About"
import Contact from "./pages/Contact/Contact";
import Education from "./pages/Education/Education";
import Internship from "./pages/Internship/Internship";
import Projects from "./pages/Projects/Projects";
import Techstack from "./pages/Techstack/Techstack";
import ScrollToTop from "react-scroll-to-top";
import { useTheme } from "./context/ThemeContext";
import Tada from 'react-reveal/Tada';
import MobileNav from "./components/MobileNav/MobileNav";

function App() {
  const [theme] = useTheme();
  return (
   <>
     <div id={theme}>
      <MobileNav/>
    <Layout />
    <div className="container">
    <About/>
    <Education />
    <Techstack />
    <Projects />
    <Internship />
    <Contact />
    </div>
    <div className="footer pb-3 ms-3">
      <Tada>
      <h5 className="text-center">
        Made With 💜 Snehal Mali &copy; 2023
      </h5>
      </Tada>
    </div>
    </div>
    <ScrollToTop 
     smooth
     color="black" 
     style={{backgroundcolor:'black',borderRadius:"80px"}}
      />

   </>
  );
}

export default App;
