import React from "react";
import "./About.css";
import HeadShake from "react-reveal/HeadShake";

const About = () => {
  return (
    <>
      <HeadShake>
        <div className="about" id="about">
          <div className="row">
            <div className="col-md-6 col-xl-6 col-lg-6 col-xs-12 about-img">
              <img src="img.jpg" alt="image"></img>
            </div>
            <div className="col-md-6 col-xl-6 col-lg-6 col-xs-12 about-content">
              <h1>About me</h1>
              <p>
                Hello! I'm Snehal ,a Front End Developer.I have compeleted my
                BTech in Electronic Engineering from DKTE Textile and
                engineering institute.I compeleted full stack development
                internship. I specialize in
                HTML,CSS,Bootstrap,Javascript,Reactjs and a bit of PHP,MongoDB.
              </p>
            </div>
          </div>
        </div>
      </HeadShake>
    </>
  );
};

export default About;
