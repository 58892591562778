import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './Internship.css'
import {SiReact} from 'react-icons/si'
const Internship = () => {
  return (
    <>
    <div className="int" id="internship">
        <div className="container work-int">
        <h2 className="col-12 mt-3 mb-1 text-center">Internship</h2>
       <hr/>
       <VerticalTimeline lineColor='#1e1e2c'>
       <VerticalTimelineElement
    className="vertical-timeline-element-work"
    contentStyle={{ background: 'white', color: '#1e1e2c' }}
    contentArrowStyle={{ borderRight: '7px solid white' }}
    date="12/2021 - 01/2022"
    iconStyle={{ background: '#1e1e2c', color: '#fff' }}
    icon={<SiReact />}
     >
    <h3 className="vertical-timeline-element-title">D-Smart Innovative Technology</h3>
    <p>Hands on Full Stack Development familiar with application
    development work using HTML,CSS,Javascript,Bootstrap.
    </p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'white', color: '#1e1e2c' }}
    contentArrowStyle={{ borderRight:  '7px solid white'}}
    iconStyle={{ background: '#1e1e2c', color: '#fff' }}
    icon={<SiReact/>}
     >
    <h5 className="vertical-timeline-element-subtitle">1.Created Admission and Application form<br></br>
    2.Created idea lab webpage<br></br> 3.Login Page</h5>
    
    </VerticalTimelineElement>
       </VerticalTimeline>
        </div>
    </div>
    </>
  )
}

export default Internship