import { BsFiletypeHtml, BsFiletypeCss } from "react-icons/bs";
import {
  RiReactjsFill,
  RiBootstrapLine,
  RiJavascriptLine,
} from "react-icons/ri";

import { AiOutlineCopyrightCircle } from "react-icons/ai";
export const Techstacklist = [
  {
    _id: 1,
    name: "HTML",
    icon: BsFiletypeHtml,
  },
  {
    _id: 2,
    name: "CSS",
    icon: BsFiletypeCss,
  },
  {
    _id: 3,
    name: "Javascript",
    icon: RiJavascriptLine,
  },
  {
    _id: 4,
    name: "ReactJs",
    icon: RiReactjsFill,
  },
  {
    _id: 5,
    name: "Bootstrap5",
    icon: RiBootstrapLine,
  },
  {
    _id: 6,
    name: "C",
    icon: AiOutlineCopyrightCircle,
  },
];
