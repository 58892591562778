import React from "react";
import Flip from "react-reveal/Flip";

import "./Projects.css";
const Projects = () => {
  return (
    <>
      <div className="container project" id="project">
        <h2 className="col-12 mt-3 mb-1 text-center">Top Recent Projects</h2>
        <hr />
        <p className="pb-3 text-center">
          👉here are my top 3 recent project with live links and source code.
        </p>
        <div className="row" id="ads">
          <Flip>
            <div className="col-md-4">
              <div className="card rounded">
                <div className="card-image">
                  <span className="card-notify-badge">Project</span>
                  <img src="exam.png" alt="project" />
                </div>
                <div className="card-image-overly m-auto mt-3">
                  <span className="card-detail-badge">Javascript</span>
                  <span className="card-detail-badge">CSS</span>
                  <span className="card-detail-badge">PHP</span>
                </div>
                <div className="card-body text-center">
                  <div className="ad-title m-auto">
                    <h5>Online Examination System</h5>
                  </div>
                  <a className="ad-btn" href="https://youtu.be/KSkLUhrzjp0">
                    view
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card rounded">
                <div className="card-image">
                  <span className="card-notify-badge">Project</span>
                  <img src="gym.png" alt="project2" />
                </div>
                <div className="card-image-overly m-auto mt-3">
                  <span className="card-detail-badge">Reactjs</span>
                  <span className="card-detail-badge">CSS</span>
                  <span className="card-detail-badge">React Hook</span>
                </div>
                <div className="card-body text-center">
                  <div className="ad-title m-auto">
                    <h5>Gym Website</h5>
                  </div>
                  <a className="ad-btn" href="https://gymwebsitesm.pages.dev/">
                    view
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card rounded">
                <div className="card-image">
                  <span className="card-notify-badge">Project</span>
                  <img src="crud.png" alt="project" />
                </div>
                <div className="card-image-overly m-auto mt-3">
                  <span className="card-detail-badge">Reactjs</span>
                </div>
                <div className="card-body text-center">
                  <div className="ad-title m-auto">
                    <h5>Crud Application</h5>
                  </div>
                  <a className="ad-btn" href="https://youtu.be/DE75c-g_5Rk">
                    view
                  </a>
                </div>
              </div>
            </div>
          </Flip>
        </div>
      </div>
    </>
  );
};

export default Projects;
