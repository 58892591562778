import React from 'react'
import {MdSchool} from 'react-icons/md'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './Education.css'


const Education = () => {
  return (
    <>
    
    <div className="education" id="education">
    <h2 className="col-12 mt-3 mb-1 text-center">Education Details</h2>
    <hr/>

    <VerticalTimeline>
    <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'white', color: 'black' }}
    contentArrowStyle={{ borderRight: '7px solid  grey' }}
    date="2019 - 2023"
    iconStyle={{ background: '#138781', color: '#fff' }}
    icon={<MdSchool />}
     >
    <h3 className="vertical-timeline-element-title">Electronic Engineering</h3>
    <h5 className="vertical-timeline-element-subtitle">DKTE socitey's Textile and Engineering collage.</h5>
    
    </VerticalTimelineElement>
    <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'white', color: 'black' }}
    contentArrowStyle={{ borderRight: '7px solid  grey' }}
    date="2017 - 2019"
    iconStyle={{ background: '#138781', color: '#fff' }}
    icon={<MdSchool />}
     >
    <h3 className="vertical-timeline-element-title">HSC</h3>
    <h5 className="vertical-timeline-element-subtitle">Kabnur highschool and junior college,ichlkaranji.</h5>
    
    </VerticalTimelineElement>
    </VerticalTimeline>
    </div>
    
    </>
  )
}

export default Education